
import { createApp, h } from 'vue'
import { createInertiaApp } from '@inertiajs/vue3'
import directives from "../js/directives";
import Notifications from '@kyvg/vue3-notification'
import {createVuetify} from "vuetify";
import * as components from 'vuetify/components';
import * as vuetifydirectives from 'vuetify/directives'
import "@mdi/font/css/materialdesignicons.min.css";
import 'vuetify/styles';
import PrimeVue from 'primevue/config';
import Aura from '@primevue/themes/aura';
import Nora from '@primevue/themes/nora';
const vuetify = createVuetify({
    components,
    vuetifydirectives
})

createInertiaApp({
    id: 'app',
    resolve: name => {
        const pages = import.meta.glob('./Pages/**/*.vue', { eager: true })
        return pages[`./Pages/${name}.vue`]
    },
    title: title => title ? `${title} - VIP Fares` : 'VIP Fares',
    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) })

        directives.forEach(directive => {
            app.directive(directive.name, directive);
        })

        app.use(plugin)
            .use(PrimeVue, {
               unstyled: true
            })
            .use(Notifications)
            .use(vuetify)
            .mount(el)

    },
})
